@tailwind base;
@tailwind components;
@tailwind utilities;
/* Default cursor for everything */
/* Default cursor for everything */
* {
    cursor: default;
  }
  
  /* Hide cursor only for title container and its children */
  .animated-title-container,
  .animated-title-container * {
    cursor: none;
  }
  
  /* Video cursor container */
  .video-cursor {
    width: 160px;
    height: 160px;
  }

  .text-outline {
    -webkit-text-stroke: 2px #000;
    color: transparent;
    font-weight: 800;
  }
  .text-element {
    line-height: 1em; /* or match it to the font-size, e.g., 20px if the font-size is 20px */
  }

  @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700&display=swap');

  .font-elegant {
    font-family: 'Space Grotesk', sans-serif;
    letter-spacing: -0.02em;
    font-feature-settings: "ss03", "zero";
  }
  
  
  
  